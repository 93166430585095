<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'legaleos',
  primary_color: '#EAE0D5',
  secondary_color: '#CC9B3C',
  header: {
    background: {
      image: `/projekt/legaleos/elbworx-projekt-legaleos-header-3840.webp`,
    },
    project_class: 'text-secondary',
    project: 'Legaleos',
    headline: 'Der digitale<br> Arbeitsplatz',
    headline_low_contrast_fix: true,
    info_card: {
      description:
        'Konzeption und Neugestaltung einer Wort-Bild-Marke inklusive Entwicklung des dazugehörigen Internet-Auftritts',
      client: 'APOYNT Software',
      roles: ['Corporate Design', 'Webdesign'],
      project_duration: '2021 bis 2022',
      project_url: 'https://legaleos.io/',
      font_black: true,
      custom_button_class: 'bg-black',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Die Herausforderung</h3>

      <p>
        Tools zur digitalen Zusammenarbeit gibt es viele — Plattformen, die hochgradig sicher und
        datenschutzkonform arbeiten, jedoch nur wenige. Dieser Herausforderung widmet sich Legaleos
        und bietet Teamarbeit und Mandantenkommunikation auf höchstem Sicherheitsniveau: speziell
        entwickelt für Anwälte, Steuerberater und Justiziare.
      </p>
      <p>
        Das bisherige Corporate Design war diesem Anspruch schon länger nicht mehr gewachsen, die
        Entwicklung eines neuen Markenauftritts daher überfällig. Wir wählten den Löwen als Sinnbild
        für Tapferkeit und Kraft: Wo ein Löwe über sensible Daten wacht, haben Räuber und
        Eindringlinge keine Chance! Das imposante Erscheinungsbild des königlichen Tiers, verbunden
        mit klassischen Grüntönen und goldfarbenen Akzenten, verkörpert Seriosität und weckt
        Vertrauen in die absolute Zuverlässigkeit des Produkts. Ein schnörkelloser
        Internet-Auftritt, der alle Vorteile klar und punktgenau abbildet, rundet den neuen
        Markenauftritt von Legaleos ab.
      </p>
    </template>

    <Grid class="items-end">
      <div class="col-span-4">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-legaleos-visitenkarte-1280.webp`"
        />
      </div>
      <div
        class="col-span-7 flex items-center justify-center bg-[#192C27] px-36 py-10 sm:h-[600px]"
      >
        <Image class="w-full" :src="`${projectPath}elbworx-projekt-legaleos-logo.svg`" />
      </div>
    </Grid>
    <Grid class="items-end">
      <div class="col-span-11 col-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-legaleos-säulen-2560.webp`"
        />
      </div>
    </Grid>

    <Tilt contain class="relative mt-14 bg-[#192C27]">
      <BackgroundMedia
        :background-image="`${projectPath}elbworx-projekt-legaleos-löwe-2560.webp`"
      />
      <Padding class="flex justify-center">
        <Device
          class="my-10 w-full max-w-3xl md:my-20"
          :element="{
            device_type: 'tablet',
            frame_color: 'white',
            image_url: `${projectPath}elbworx-projekt-legaleos-startseite-1920.webp`,
          }"
        />
      </Padding>
    </Tilt>
  </Project>
</template>
